import { ReactNode } from 'react'

import { Media } from '../MediaContext'

const MediaBreakpointLG = ({
  greaterOrEqual,
  lessThan,
}: {
  greaterOrEqual: ReactNode
  lessThan: ReactNode
}) => (
  <>
    <Media lessThan="lg">{lessThan}</Media>
    <Media greaterThanOrEqual="lg">{greaterOrEqual}</Media>
  </>
)

export default MediaBreakpointLG
